import React from "react";
import { useRouteError, Link } from "react-router-dom";

const ErrorBoundary = () => {
	const error = useRouteError(); // Get error from React Router
	console.error("Error caught by ErrorBoundary:", error); // Log for debugging
	//
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				textAlign: "center",
			}}
		>
			<h1>Oops! Something went wrong</h1>
			<p>
				{error?.message ||
					"An unexpected error occurred. Please try again."}
			</p>
			<Link
				to="/"
				style={{
					textDecoration: "none",
					color: "blue",
					marginTop: "10px",
				}}
			>
				Return to Home
			</Link>
		</div>
	);
};

export default ErrorBoundary;
