import React, { useEffect, useRef, useState } from "react";
import globalAxios from "../../globalAxios.js";
import { SERVER_URL } from "../../config.js";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import { useAppSelector, useAppDispatch } from "../../store/hooks.ts";
import {
	updateEvent,
	setCalendarView,
	setCalendarDate,
	removeSession,
	deleteEventsByMonth,
} from "../../store/slices/calendarSlice.ts";

import { Box, Button, CircularProgress, Grid } from "@mui/material";

import "./Calendar.css";
import { contentToDisplay, formatEvents } from "./helper/eventRendering.tsx";
import CalendarDialog from "./calendardialog/CalendarDialog.tsx";

interface SelectedSession {
	title: string;
	id: string;
	start: Date;
	end: Date;
	surgeon: {
		id: string;
		acronym?: string;
		name: string;
		[key: string]: any; // Allows flexibility for additional properties
	};
	hospital: {
		id: string;
		acronym?: string;
		name: string;
		[key: string]: any; // Allows flexibility for additional properties
	};
	events: Array<{
		id: string;
		actual_items: string; // JSON string
		[key: string]: any; // To allow other dynamic properties
	}>;
}

const Calendar = () => {
	const calendarRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false); // for single event.
	const [deletingEvents, setDeletingEvents] = useState(false); // for current month of events.

	const [selectedSession, setSelectedSession] = useState<SelectedSession>({
		title: "",
		id: "",
		start: new Date(),
		end: new Date(),
		surgeon: {} as SelectedSession["surgeon"], // TypeScript will treat this as a valid surgeon object
		hospital: {} as SelectedSession["hospital"],
		events: [],
	});

	const events = useAppSelector((state) => state.calendar.events);
	const currentLens = useAppSelector((state) => state.calendar.lens);
	const currentView = useAppSelector((state) => state.calendar.view);
	const currentDate = useAppSelector((state) => state.calendar.currentDate);
	const isAdmin = useAppSelector((state) => state.user.admin);

	const user = useAppSelector((state) => state.user);

	const [schedules, setSchedules] = useState([]);
	const extractItemNumbersFromRedux = (events) => {
		const itemNumbers = [];

		events.forEach((event) => {
			event.events.forEach((subEvent) => {
				try {
					// Skip if actual_items is null, undefined, or the string "null"
					if (
						!subEvent.actual_items ||
						subEvent.actual_items === "null"
					) {
						return;
					}

					// Parse actual_items which is in JSON string format
					const parsedItems = JSON.parse(subEvent.actual_items);

					// Skip if parsedItems is not an array (safety check)
					if (!Array.isArray(parsedItems)) {
						return;
					}

					// Filter only numeric items
					const numericItems = parsedItems.filter(
						(item) => !isNaN(item)
					);

					// Collect only numeric item numbers
					itemNumbers.push(...numericItems);
				} catch (error) {
					console.error("Error parsing actual_items:", error);
				}
			});
		});

		// Return unique item numbers
		return [...new Set(itemNumbers)];
	};
	const fetchSchedules = async (itemNumbers) => {
		if (itemNumbers.length === 0) return; // Don't fetch if no item numbers

		try {
			const response = await globalAxios.get(`${SERVER_URL}/schedules`, {
				params: { itemNums: itemNumbers },
			});

			setSchedules(response.data); // Store the fetched schedules
		} catch (error) {
			console.error("Error fetching schedules:", error);
		}
	};

	// Call this after extracting item numbers from Redux
	useEffect(() => {
		const itemNumbers = extractItemNumbersFromRedux(events); // Use the events from Redux
		fetchSchedules(itemNumbers);
	}, [events]); // Re-run when Redux events change

	const dispatch = useAppDispatch();

	const handleEventClick = (clickInfo) => {
		const { title, id, start, end, extendedProps } = clickInfo.event;

		setSelectedSession({
			title: title,
			id: id,
			start: start,
			end: end,
			surgeon: {
				...extendedProps.surgeon,
				name:
					extendedProps.surgeon.first_name +
					" " +
					extendedProps.surgeon.last_name,
			},
			hospital: extendedProps.hospital,
			events: extendedProps.events,
		});

		setOpen(true);
	};

	// Enable editable inputs in calendar dialog.
	const [editSession, setEditSession] = useState(false);

	const [hospitals, setHospitals] = useState([]);
	const [surgeons, setSurgeons] = useState([]);
	const handleEditSession = () => {
		if (!editSession) {
			// Fetch data for drop down selection.
			Promise.all([
				globalAxios.get(`${SERVER_URL}/hospitals`),
				globalAxios.get(`${SERVER_URL}/surgeons`),
			])
				.then(([hospitalRes, surgeonRes]) => {
					setHospitals(hospitalRes.data.hospitals);
					setSurgeons(surgeonRes.data.surgeons);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		}
		setEditSession(editSession ? false : true);
	};

	// Enable editable inputs in Event dialog.
	const [editEvent, setEditEvent] = useState(false);

	const [healthFunds, setHealthFunds] = useState([]);
	const [anaesthetists, setAnaesthetists] = useState([]);

	const handleEditEvent = async () => {
		if (!editEvent) {
			setEditEvent(true);

			try {
				const [hfRes, anaesRes] = await Promise.all([
					globalAxios.get(`${SERVER_URL}/healthfunds`),
					globalAxios.get(`${SERVER_URL}/anaesthetists`),
				]);
				setHealthFunds(hfRes.data.healthFunds); // Adjust based on your backend shape
				setAnaesthetists(anaesRes.data.anaesthetists);
			} catch (error) {
				console.error("Error fetching dropdown data:", error);
			}
		}

		// setEditEvent(editEvent ? false : true); // toggle edit
	};

	const handleClose = () => {
		setOpen(false);
		setEditSession(false);
		setSelectedSession({
			title: "",
			id: "",
			start: new Date(),
			end: new Date(),
			surgeon: {} as SelectedEvent["surgeon"], // TypeScript will treat this as a valid surgeon object
			hospital: {} as SelectedEvent["hospital"],
			events: [],
		});
	};

	// Update Items in the FE.
	const handleUpdateEventItems = async (eventId, updatedItems) => {
		try {
			// Update the local state
			setSelectedSession((prevSelectedSession) => ({
				...prevSelectedSession,
				events: prevSelectedSession.events.map((event) =>
					event.id === eventId
						? {
								...event,
								actual_items: JSON.stringify(updatedItems),
						  }
						: event
				),
			}));
		} catch (error) {
			console.error("Error updating event items:", error);
		}
	};

	//  Add new item to an event FE.
	const handleAddEventItem = (eventIndex, newItem) => {
		const updatedEvents = [...selectedEvent.events];

		const currentItems = JSON.parse(
			updatedEvents[eventIndex].actual_items || "[]"
		);

		// Add the new item
		currentItems.push(newItem);

		// Update the `actual_items` field
		updatedEvents[eventIndex].actual_items = JSON.stringify(currentItems);

		// Update the selected event state
		setSelectedSession((prevSelectedSession) => ({
			...prevSelectedSession,
			events: updatedEvents,
		}));
	};

	// Remove item from and event FE.
	const handleDeleteEventItem = (eventIndex, itemIndex) => {
		const updatedEvents = [...selectedSession.events];

		const currentItems = JSON.parse(
			updatedEvents[eventIndex].actual_items || "[]"
		);

		// Remove the item at the specified index
		currentItems.splice(itemIndex, 1);

		// Update the `actual_items` field
		updatedEvents[eventIndex].actual_items = JSON.stringify(currentItems);

		// Update the selected event state
		setSelectedSession((prevSelectedSession) => ({
			...prevSelectedSession,
			events: updatedEvents,
		}));
	};

	const handleSaveEventItems = async (sessionId, eventId, items) => {
		try {
			setSaving(true);

			const parsedItems = items
				.map((item) => parseInt(item, 10))
				.filter((item) => !isNaN(item));

			const response = await globalAxios.put(
				`${SERVER_URL}/user/events/${eventId}`,
				{
					actual_items: parsedItems,
				}
			);

			if (response.status === 200) {
				const { items_validated, invalid_items } = response.data;

				// Dispatch the update to Redux
				dispatch(
					updateEvent({
						sessionId,
						eventId,
						updates: {
							actual_items: JSON.stringify(parsedItems),
							items_validated,
						},
					})
				);

				setSelectedSession((prevSelectedSession) => ({
					...prevSelectedSession,
					events: prevSelectedSession.events.map((event) =>
						event.id === eventId
							? {
									...event,
									// actual_items: JSON.stringify(parsedItems),
									items_validated,
							  }
							: event
					),
				}));

				return {
					success: true,
					invalid_items,
					message: response.data.message,
				};
			}
		} catch (error) {
			console.error("Error saving event items:", error);

			if (error.response?.status === 400) {
				return {
					success: false,
					invalid_items: error.response.data.invalid_items || [],
					message:
						error.response.data.message || "Validation failed.",
				};
			}

			return { success: false, message: "An unexpected error occurred." };
		} finally {
			setSaving(false);
		}
	};

	const handleDelete = async () => {
		console.log("Deleting event:", selectedSession);

		if (!selectedSession.id) {
			console.error("No event selected or missing event ID.");
			return;
		}

		setDeleting(true);

		try {
			// Replace `/api/sessions/${selectedEvent.id}` with your actual endpoint that handles session deletion
			const response = await globalAxios.delete(
				`${SERVER_URL}/user/sessions/${selectedSession.id}`
			);

			if (response.status === 200) {
				console.log("Session deleted successfully.");
				dispatch(removeSession(parseInt(selectedSession.id)));
			}
		} catch (error) {
			console.error("Error deleting session:", error);
		} finally {
			setDeleting(false);
			setOpen(false);
		}
	};

	const eventDidMount = (info) => {
		// Change the styling from bullet point to block.
		if (info.view.type === "dayGridMonth") {
			const eventDate = info.event.startStr.split("T")[0];
			const eventsOnThisDay = document.querySelectorAll(
				`[data-date="${eventDate}"] .fc-event`
			);
			if (eventsOnThisDay.length > 3) {
				// Hide excess events
				for (let i = 3; i < eventsOnThisDay.length; i++) {
					eventsOnThisDay[i].style.display = "none";
				}
			}
		}
	};

	// Format the event data for use in the render fn.
	const convertEventsFormat = (view, eventsArray, user) => {
		return formatEvents(view, eventsArray, user, schedules);
	};

	// Styling display for individual events display on the current calendar view.
	const renderEventContent = (eventInfo) => {
		// Get the content and background colour from the contentToDisplay function
		const { content, backgroundColour } = contentToDisplay(
			user.settings,
			eventInfo,
			currentLens,
			schedules
		);

		return (
			<div
				style={{
					// Background colour is for modified monthly view events.
					backgroundColor: backgroundColour,
					borderColor: backgroundColour,
					color: "white",
					padding: "2px 4px",
					height: "100%",
					width: "100%",
					// borderRadius: isDayGridMonth ? "3px" : "0",
					// display: isDayGridMonth ? "block" : "inline-block",
				}}
			>
				{content}
			</div>
		);
	};

	//  Used to determine current month to delete all monthly events.
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Track current month
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [isMonthlyView, setIsMonthlyView] = useState(true);

	// Update current month and view type
	const handleDatesSet = (dateInfo) => {
		const { start, view } = dateInfo;

		setCurrentMonth(start.getMonth());
		setCurrentYear(start.getFullYear());
		setIsMonthlyView(view.type === "dayGridMonth");

		dispatch(setCalendarView(view.type));
		dispatch(setCalendarDate(start.toISOString()));
	};

	const handleDeleteEvents = async () => {
		setDeletingEvents(true);

		// Format the date as YYYY-MM
		const formattedDate = `${currentYear}-${(currentMonth + 1)
			.toString()
			.padStart(2, "0")}`;

		// axios request to delete all events within the current month
		try {
			const response = await globalAxios.delete(
				`${SERVER_URL}/user/sessions`,
				{
					data: { date: formattedDate }, // Pass the formatted date as data in the request body
				}
			);
			if (response.status === 200) {
				// Dispatch the Redux action to update the state after deletion
				dispatch(deleteEventsByMonth(formattedDate));
			} else {
				console.error(
					"Failed to delete events:",
					response.data.message
				);
			}
		} catch (e) {
			console.error("Error deleting events:", e);
		} finally {
			setDeletingEvents(false);
		}
	};

	useEffect(() => {
		if (calendarRef.current && currentDate) {
			const calendarApi = calendarRef.current.getApi();
			calendarApi.gotoDate(new Date(currentDate)); // Set the date dynamically
		}
	}, [currentDate]);

	return (
		<Box
			sx={{
				"& .fc": {
					maxHeight: "90vh",
					minHeight: "90vh",
				},
				"& .fc-event": {
					cursor: "pointer",
				},
			}}
		>
			<FullCalendar
				ref={calendarRef}
				// fixedWeekCount={false}
				showNonCurrentDates={false}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
					interactionPlugin,
				]}
				initialView={currentView}
				initialDate={currentDate}
				slotMinTime="08:00:00" // Start the day at 8 AM
				slotMaxTime="22:00:00"
				slotLabelInterval="05:00:00"
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay listMonth",
				}}
				firstDay={1}
				editable={true}
				expandRows={true}
				allDaySlot={false}
				events={convertEventsFormat(currentLens, events, user)}
				eventClick={handleEventClick}
				eventDidMount={eventDidMount} // Apply hiding logic after event is mounted
				datesSet={handleDatesSet} // Track the current month and view type
				viewDidMount={(viewInfo) => {
					if (viewInfo.view.type === "dayGridMonth") {
						document
							.querySelectorAll(".fc-event")
							.forEach((eventEl) => {
								eventEl.classList.add("fc-daygrid-event");
							});
					}
				}}
				eventContent={renderEventContent}
				eventTimeFormat={{
					hour: "2-digit",
					minute: "2-digit",
					hour12: false, // Use 24-hour format
				}}
			/>

			<Grid container>
				{isAdmin && isMonthlyView ? (
					<Grid item xs={2}>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							alignItems="center"
							sx={{ marginTop: "1rem" }}
						>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									disableElevation
									sx={{ color: "white" }}
									onClick={handleDeleteEvents}
									disabled={deletingEvents} // Button is disabled when loading is true
								>
									{deletingEvents ? (
										<CircularProgress
											size={24}
											sx={{ color: "white" }}
										/>
									) : (
										"Delete Events"
									)}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				) : (
					""
				)}
			</Grid>

			<CalendarDialog
				open={open}
				selectedSession={selectedSession}
				setSelectedSession={setSelectedSession}
				schedules={schedules}
				saving={saving}
				deleting={deleting}
				edit={editSession}
				handleEdit={handleEditSession}
				editEvent={editEvent}
				setEditEvent={setEditEvent}
				handleEditEvent={handleEditEvent}
				hospitals={hospitals}
				surgeons={surgeons}
				healthFunds={healthFunds}
				anaesthetists={anaesthetists}
				handleDelete={handleDelete}
				handleClose={handleClose}
				handleUpdateEventItems={handleUpdateEventItems}
				handleAddEventItem={handleAddEventItem}
				handleDeleteEventItem={handleDeleteEventItem}
				handleSaveEventItems={handleSaveEventItems}
			/>
		</Box>
	);
};

export default Calendar;
