//  Calculate fee estimate for a "Session".
export const calculateFeeEstimate = (userSettings, subEvents, schedules) => {
	let totalFee = 0;

	subEvents.forEach((subEvent) => {
		const singlePtFeeEstimate = calculateSinglePtFeeEstimate(
			userSettings,
			subEvent,
			schedules
		);
		if (singlePtFeeEstimate) {
			totalFee += singlePtFeeEstimate;
		}
	});

	return totalFee > 0 ? totalFee : null;
};

export const calculateSinglePtFeeEstimate = (
	userSettings,
	subEvent,
	schedules
) => {
	if (!subEvent.actual_items || subEvent.actual_items === "null") {
		return 0; // Skip if no items and return 0 for this patient
	}
	// Parse actual_items
	let parsedItems = [];
	try {
		parsedItems = JSON.parse(subEvent.actual_items);
	} catch (error) {
		console.error("Error parsing actual_items:", error);
		return 0; // Return 0 in case of an error
	}

	// Filter only numeric items
	const numericItems = parsedItems.filter((item) => {
		const matchingSchedule = schedules.find(
			(schedule) => Number(schedule.item_num) === Number(item)
		);
		return (
			!isNaN(item) &&
			matchingSchedule &&
			matchingSchedule.description?.includes("(Assist.)")
		);
	});

	// Sort numeric items by their corresponding schedule_fee in descending order
	const sortedItems = numericItems.sort((a, b) => {
		const feeA =
			schedules.find(
				(schedule) => Number(schedule.item_num) === Number(a)
			)?.schedule_fee || 0;

		const feeB =
			schedules.find(
				(schedule) => Number(schedule.item_num) === Number(b)
			)?.schedule_fee || 0;

		return feeB - feeA; // Sort descending by fee
	});

	let totalPatientFee = 0;
	let usingFee51300 = false;

	sortedItems.forEach((itemNumber, index) => {
		const matchingSchedule = schedules.find(
			(schedule) => Number(schedule.item_num) === Number(itemNumber)
		);
		if (matchingSchedule && matchingSchedule.schedule_fee) {
			let fee = matchingSchedule.schedule_fee;

			// Apply the multiplier based on the position of the item
			if (index === 0) {
				totalPatientFee += fee; // 100% for the first item
			} else if (index === 1) {
				totalPatientFee += fee * 0.5; // 50% for the second item
			} else {
				totalPatientFee += fee * 0.25; // 25% for all remaining items
			}
		}
	});

	// Only check for 51300 if there are valid (Assist.) items
	if (numericItems.length > 0 && totalPatientFee < 636.05) {
		const fee51300 = schedules.find(
			(schedule) => Number(schedule.item_num) === 51300
		)?.schedule_fee;

		if (fee51300) {
			totalPatientFee = fee51300; // Use the 51300 fee if total is less than 636.05
			usingFee51300 = true;
		}
	}

	const healthFundType = subEvent.patient?.policies?.[0]?.healthFund?.type;
	let healthFundMultiplier = 1;

	if (healthFundType) {
		switch (healthFundType.toUpperCase()) {
			case "AHSA":
				healthFundMultiplier = 1.47; // 147% of MC
				break;
			case "BUPA":
				healthFundMultiplier = 1.4; // Apply 140% of MC (adjust as needed if gap status is known)
				break;
			case "MEDIBANK PRIVATE":
				healthFundMultiplier = 1.50762; // 150.762% of MC
				break;
			case "HBF":
				// Apply WA and non-WA rates (adjust as needed based on location)
				healthFundMultiplier = 1.47; // Assume non-WA for this example
				break;
			case "HCF":
				healthFundMultiplier = 1.41; // 141% of MC
				break;
			case "LHS / LHC":
				healthFundMultiplier = 1.25; // 125% of MC
				break;
			case "MDHF":
				healthFundMultiplier = 1.2575; // 125.75% of MC
				break;
			case "NIB":
				healthFundMultiplier = 1.2575; // 125.75% of MC
				break;
			case "SLH":
				healthFundMultiplier = 1.52; // 152% of MC
				break;
			default:
				break; // No specific multiplier for other health funds
		}
	} else {
		healthFundMultiplier = 0.85;
	}

	if (usingFee51300) {
		totalPatientFee *= healthFundMultiplier;
	} else {
		// Apply the health fund multiplier and multiply by 0.2 if not using fee 51300
		totalPatientFee = totalPatientFee * healthFundMultiplier * 0.2;
	}

	// Apply tier based adjustment
	if (userSettings.tiers && subEvent.billing_tier) {
		const surgeonTier = subEvent.billing_tier.surgeon_tier;
		const hospitalTier = subEvent.billing_tier.hospital_tier;

		// Find matching tiers in user settings
		const surgeonTierValue = userSettings.tiers.find(
			(tier) => tier.name === surgeonTier
		)?.value;

		const hospitalTierValue = userSettings.tiers.find(
			(tier) => tier.name === hospitalTier
		)?.value;

		// Apply tier adjustments if they exist
		if (surgeonTierValue) {
			totalPatientFee += parseFloat(surgeonTierValue);
		}
		if (hospitalTierValue) {
			totalPatientFee += parseFloat(hospitalTierValue);
		}
	}

	return totalPatientFee; // Return the total fee for this patient
};
