// components/LensView.tsx

import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks.ts"; // Assuming you have this set up

import {
	Box,
	Button,
	ClickAwayListener,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@mui/material";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import ReactDOM from "react-dom";
import { updateColorConfig } from "../../../store/slices/userSlice.ts";
import globalAxios from "../../../globalAxios.js";
import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../../store/slices/snackbarSlice.ts";

const Legend = () => {
	const dispatch = useAppDispatch();
	const currentLens = useAppSelector((state) => state.calendar.lens);
	const userSettings = useAppSelector((state) => state.user.settings);
	const initialColors = userSettings?.calendar?.colorConfig || {};

	const [selectedColor, setSelectedColor] = useColor("#1976d2");
	const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
	const [colorConfig, setColorConfig] =
		useState<Record<string, Record<string, string>>>(initialColors);

	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const [pickerPosition, setPickerPosition] = useState<{
		top: number;
		left: number;
	}>({ top: 0, left: 0 });

	const pickerRef = useRef<HTMLDivElement | null>(null);
	// Define the colour legend based on the current view
	const getLegendForView = () => {
		switch (currentLens) {
			case "location":
				return [
					{ label: "Morning", color: "#1976d2" },
					{ label: "Afternoon", color: "#f57c00" },
					{ label: "Twilight", color: "#388e3c" },
					{ label: "Unknown", color: "grey" },
				];
			case "uploadStatus":
				return [
					{ label: "Upload Complete", color: "#388e3c" },
					{ label: "Upload Pending", color: "#f57c00" },
				];
			case "feeEstimate":
				return [
					{ label: "Estimate", color: "#388e3c" },
					{ label: "No Estimate", color: "grey" },
				];
			case "fundingStatus":
				return [
					{ label: "Funding Available", color: "#388e3c" },
					{ label: "Funding Missing", color: "#fbc02d" },
				];
			default:
				return [];
		}
	};

	const legend = getLegendForView();

	const handleColorBoxClick = (event, item) => {
		setSelectedLabel(item.label);
		setSelectedColor((prev) => ({
			...prev,
			hex:
				colorConfig[currentLens]?.[item.label] ||
				legend.find((el) => el.label === item.label)?.color ||
				item.color,
		}));

		const rgb = hexToRgb(
			colorConfig[currentLens]?.[item.label] ||
				legend.find((el) => el.label === item.label)?.color ||
				item.color
		);
		const hsv = rgbToHsv(rgb);

		setSelectedColor({
			hex:
				colorConfig[currentLens]?.[item.label] ||
				legend.find((el) => el.label === item.label)?.color ||
				item.color,
			rgb,
			hsv,
		});

		const rect = event.currentTarget.getBoundingClientRect();
		setPickerPosition({
			top: rect.top,
			left: rect.left + 30,
		});
		setIsPickerOpen(true);
	};

	const handleClickAway = (event) => {
		if (
			pickerRef.current &&
			pickerRef.current.contains(event.target as Node)
		) {
			return;
		}
		setIsPickerOpen(false);
	};

	const handleSaveColor = async () => {
		if (!selectedLabel) return;

		const newHex = selectedColor.hex;
		const newColorConfig = {
			...colorConfig,
			[currentLens]: {
				...((colorConfig[currentLens] as unknown as Record<
					string,
					string
				>) || {}),
				[selectedLabel]: newHex,
			},
		};

		setColorConfig(newColorConfig);
		setIsPickerOpen(false);

		dispatch(updateColorConfig(newColorConfig));

		try {
			await globalAxios.post("/user/settings/calendar/color-config", {
				colorConfig: newColorConfig,
			});
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("success"));
			dispatch(setSnackbarText("Update saved!"));
		} catch (error) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(
				setSnackbarText(`❌ Error updating color settings: ${error}`)
			);
		}
	};

	return (
		<Box>
			<Typography
				variant="body1"
				gutterBottom
				fontWeight={600}
				textAlign="center"
			>
				Legend
			</Typography>
			<List>
				{legend.map((item, index) => (
					<ListItem
						key={index}
						sx={{ display: "flex", alignItems: "center" }}
					>
						{/* Colour Box */}
						<Box
							sx={{
								width: 20,
								height: 20,
								backgroundColor:
									colorConfig[currentLens]?.[item.label] ||
									legend.find((el) => el.label === item.label)
										?.color ||
									item.color,
								marginRight: 2,
								borderRadius: "50%", // Optional for rounded circles
							}}
							onClick={(e) => handleColorBoxClick(e, item)}
						/>
						<ListItemText primary={item.label} />
					</ListItem>
				))}
			</List>
			{isPickerOpen &&
				ReactDOM.createPortal(
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box
							ref={pickerRef}
							sx={{
								position: "fixed",
								top: pickerPosition.top,
								left: pickerPosition.left,
								backgroundColor: "white",
								padding: 2,
								paddingTop: 1,
								zIndex: 9999,
							}}
						>
							<Typography
								fontWeight="bold"
								variant="subtitle1"
								gutterBottom
							>
								{selectedLabel}
							</Typography>
							<ColorPicker
								hideInput={["rgb", "hsv"]}
								color={selectedColor}
								onChange={setSelectedColor}
							/>
							<Button
								disableElevation
								size="small"
								variant="contained"
								sx={{ mt: 1 }}
								onClick={handleSaveColor}
							>
								Save Changes
							</Button>
						</Box>
					</ClickAwayListener>,
					document.body
				)}
		</Box>
	);
};

export default Legend;

const hexToRgb = (hex: string) => {
	let r = 0,
		g = 0,
		b = 0;
	if (hex.length === 7) {
		r = parseInt(hex.slice(1, 3), 16);
		g = parseInt(hex.slice(3, 5), 16);
		b = parseInt(hex.slice(5, 7), 16);
	}
	return { r, g, b, a: 1 }; // ✅ Added `a: 1` for full opacity
};

const rgbToHsv = ({ r, g, b }) => {
	let max = Math.max(r, g, b),
		min = Math.min(r, g, b);
	let h = 0,
		s = 0,
		v = max;

	let d = max - min;
	s = max === 0 ? 0 : d / max;

	if (max !== min) {
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}
	return { h: h * 360, s: s * 100, v: (v / 255) * 100, a: 1 }; // ✅ Added `a: 1` for full opacity
};
