import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				textAlign: "center",
			}}
		>
			<h1>404 - Page Not Found</h1>
			<p>Oops! The page you are looking for does not exist.</p>
			<Link to="/" style={{ textDecoration: "none", color: "blue" }}>
				Return to Home
			</Link>
		</div>
	);
};

export default NotFound;
