import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import globalAxios from "./globalAxios.js";

import { useAppSelector, useAppDispatch } from "./store/hooks.ts";
import { setLoading, setAuth } from "./store/slices/authenticateSlice.ts";
import { setUser } from "./store/slices/userSlice.ts";
import { setSessions } from "./store/slices/calendarSlice.ts";
import { setSelectedPreset, setAllFields } from "./store/slices/fieldsSlice.ts";

import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from "react-router-dom";

import { store } from "./store/store.ts";
import { Provider as ReduxProvider } from "react-redux";
import { SERVER_URL } from "./config.js";

import Login from "./pages/login/Login.tsx";
import App from "./App.js";
import Template from "./pages/template/Template.tsx";
import Calendar from "./pages/calendar/Calendar.tsx";
import Settings from "./pages/settings/Settings.tsx";
import Profile from "./pages/profile/Profile.tsx";
import Admin from "./pages/admin/Admin.tsx";

import ErrorBoundary from "./pages/fallback/ErrorBoundary.tsx";
import NotFound from "./pages/fallback/NotFound.tsx";

import ProtectedRoute, {
	AdminRoute,
} from "./components/protected/ProtectedRoute.tsx";

import { ThemeProvider } from "@mui/material";
import theme from "./themeOverride.ts";

import logo from "./SwiftMed Logo.png";

import "./index.css";

// import reportWebVitals from './reportWebVitals';

const checkPersistentSignIn = (dispatch) => {
	dispatch(setLoading(true));
	// Directly attempt to validate the session with the server
	globalAxios
		.get(SERVER_URL + "/validate-session") // Endpoint to validate session based on access token.
		.then((response) => {
			if (response.status === 200 && response.data.isValid) {
				dispatch(setAuth(true)); // Set authentication state
				dispatch(setUser(response.data.user)); // Update user data in Redux state with the data returned from the server

				if (
					response.data.user.settings &&
					response.data.user.settings.defaultPreset
				) {
					dispatch(
						setSelectedPreset(
							response.data.user.settings.defaultPreset.name
						)
					);
					dispatch(
						setAllFields(
							response.data.user.settings.defaultPreset.fields
						)
					);
				} else {
					console.log("No default fields to set.");
				}

				// Optionally, fetch user sessions here
				fetchUserSessions(dispatch);
			} else {
				// Handle the case where the session is not valid
				dispatch(setAuth(false));
			}
		})
		.catch((error) => {
			console.error("Session validation failed:", error);
			dispatch(setAuth(false));
			dispatch(setLoading(false));
		})
		.finally(() => {
			dispatch(setLoading(false));
		});
};

export const fetchUserSessions = (dispatch) => {
	globalAxios
		.get(SERVER_URL + "/user/sessions")
		.then((response) => {
			if (response.status === 200) {
				const sessions = response.data;
				dispatch(setSessions(sessions));
			}
		})
		.catch((error) => {
			console.error("Error fetching user sessions:", error);
		});
};

// Define a wrapper component for initialization
const AppInitializer = () => {
	const isLoading = useAppSelector((state) => state.authenticate.isLoading);

	const dispatch = useAppDispatch();

	useEffect(() => {
		checkPersistentSignIn(dispatch);
	}, [dispatch]);

	if (isLoading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
				className="spinner"
			>
				<img src={logo} alt="Loading..." className="logo" />
			</div>
		);
	}

	return <RouterProvider router={router} />;
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			{/* Public Routes */}
			<Route path="login" element={<Login page="signin" />} />
			<Route path="signup" element={<Login page="signup" />} />
			<Route
				path="reset-password"
				element={<Login page="resetPassword" />}
			/>
			{/* Protected Routes */}
			<Route
				element={<ProtectedRoute />}
				errorElement={<ErrorBoundary />}
			>
				<Route path="/" element={<App />}>
					<Route index element={<Calendar />} />{" "}
					{/* The 'index' route will render at the '/' path */}
					<Route path="template" element={<Template />} />
					<Route path="calendar" element={<Calendar />} />{" "}
					{/* This seems redundant if 'index' is Calendar */}
					<Route path="settings" element={<Settings />} />
					<Route path="profile" element={<Profile />} />
					<Route element={<AdminRoute />}>
						<Route path="admin" element={<Admin />} />
					</Route>
					{/* ... other protected routes */}
				</Route>
			</Route>

			<Route path="*" element={<NotFound />} />
		</>
	)
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<ThemeProvider theme={theme}>
				<AppInitializer />
			</ThemeProvider>
		</ReduxProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
