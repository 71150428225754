import React, { useState } from "react";

import _cloneDeep from "lodash/cloneDeep";

import { useAppDispatch } from "../../../../store/hooks.ts";
import { SERVER_URL } from "../../../../config";
import globalAxios from "../../../../globalAxios";

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Box,
	Divider,
	IconButton,
	TextField,
	Tooltip,
	CircularProgress,
	Autocomplete,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../../../store/slices/snackbarSlice.ts";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import ListboxComponent from "../listbox/ListBox.tsx";
import { updateSession } from "../../../../store/slices/calendarSlice.ts";

const EventDialog = ({
	open,
	onClose,
	selectedSession,
	eventIndex,
	sessionId,
	handleUpdateEventItems,
	itemChangesMade,
	invalidItems,
	setItemChangesMade,
	handleSaveItems,
	eventFieldChanges,
	setEventFieldChanges,
	setSelectedSession,
	healthFunds,
	anaesthetists,
	edit,
	setEdit,
	handleEdit,
}) => {
	const dispatch = useAppDispatch();

	const [newItem, setNewItem] = useState("");
	const [isSaving, setIsSaving] = useState(false);
	const [isSavingEvent, setIsSavingEvent] = useState(false);

	if (!selectedSession.events[eventIndex]) return null;

	const event = selectedSession.events[eventIndex];

	const handleItemChange = (itemIndex, newValue) => {
		const updatedEvents = [...selectedSession.events];

		const currentItems = JSON.parse(
			updatedEvents[eventIndex].actual_items || "[]"
		);

		currentItems[itemIndex] = newValue;

		updatedEvents[eventIndex].actual_items = JSON.stringify(currentItems);

		handleUpdateEventItems(selectedSession.id, updatedEvents);
		setItemChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};
	const handleUpdateField = (fieldPath, value) => {
		const pathParts = fieldPath.split(".");
		const updatedSession = _cloneDeep(selectedSession); // ✅ deep clone
		const targetEvent = updatedSession.events[eventIndex];

		let target = targetEvent;
		for (let i = 0; i < pathParts.length - 1; i++) {
			const key = isNaN(pathParts[i])
				? pathParts[i]
				: parseInt(pathParts[i]);
			target = target[key];
		}

		const finalKey = pathParts[pathParts.length - 1];
		target[finalKey] = value;

		setSelectedSession(updatedSession);

		setEventFieldChanges((prev) => ({ ...prev, [eventIndex]: true }));
	};

	const handleDobChange = (newValue) => {
		handleUpdateField(
			"patient.date_of_birth",
			newValue.toISOString().split("T")[0]
		);
	};

	const handleAddItem = () => {
		// if (newItem.trim()) {
		// 	setItems([...items, newItem.trim()]);
		// 	setNewItem("");
		// 	setItemsValidated(null); // reset validation
		// }
	};

	const handleDeleteItem = (index) => {
		// const updated = [...items];
		// updated.splice(index, 1);
		// setItems(updated);
		// setItemsValidated(null); // reset validation
	};

	const handleSaveEvent = async () => {
		const event = selectedSession.events[eventIndex];

		const updatePayload = {
			procedure: event.procedure,
			anaesthetist: event.anaesthetist, // Should include `.id` if used on backend
			patient: {
				first_name: event.patient?.first_name,
				last_name: event.patient?.last_name,
				date_of_birth: event.patient?.date_of_birth,
				medicare: event.patient?.medicare,
				healthFund: event.patient?.policies?.[0]?.healthFund,
				health_fund_no: event.patient?.policies?.[0]?.health_fund_no,
			},
		};

		try {
			const response = await globalAxios.patch(
				`${SERVER_URL}/user/events/${event.id}`,
				updatePayload
			);

			if (response.status === 200) {
				const updatedEvent = response.data.event;

				// 🔁 Replace event at index with updatedEvent in selectedSession
				const updatedEvents = [...selectedSession.events];
				updatedEvents[eventIndex] = updatedEvent;

				const updatedSession = {
					...selectedSession,
					events: updatedEvents,
				};

				setSelectedSession(updatedSession);

				dispatch(
					updateSession({
						sessionId: selectedSession.id,
						updates: { events: updatedEvents as [] },
					})
				);

				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Event updated successfully."));
				setEdit(false);
			} else {
				throw new Error(response.data.message || "Update failed");
			}
		} catch (error) {
			console.error("Error saving event:", error);
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Failed to update event."));
		}
	};

	return (
		<Dialog fullScreen open={open} onClose={onClose}>
			<DialogTitle
				id="form-dialog-title"
				sx={{
					backgroundColor: (theme) => theme.palette.primary.main, // Apply primary color
					color: "white", // Ensure text is readable
					padding: "12px 24px", // Add padding
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				Event Details
				<Button
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
					}}
				>
					<Typography>Close</Typography>
				</Button>
			</DialogTitle>
			<DialogContent>
				<Box display="flex" flexDirection="column" gap={2} mt={2}>
					{edit ? (
						<Box display="flex" gap={1} flexWrap="wrap">
							<TextField
								label="First Name"
								value={event.patient?.first_name || ""}
								onChange={(e) =>
									handleUpdateField(
										"patient.first_name",
										e.target.value
									)
								}
								size="small"
							/>
							<TextField
								label="Last Name"
								value={event.patient?.last_name || ""}
								onChange={(e) =>
									handleUpdateField(
										"patient.last_name",
										e.target.value
									)
								}
								size="small"
							/>
						</Box>
					) : (
						<Typography variant="h6">
							{
								selectedSession.events[eventIndex].patient
									?.first_name
							}{" "}
							{
								selectedSession.events[eventIndex].patient
									?.last_name
							}
						</Typography>
					)}

					<Divider />

					<Box display="flex" alignItems="center" gap={1}>
						<Typography variant="body1" fontWeight="bold">
							Date of Birth:
						</Typography>
						{edit ? (
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									format="yyyy-MM-dd"
									value={
										selectedSession.events[eventIndex]
											.patient?.date_of_birth
											? new Date(
													selectedSession.events[
														eventIndex
													].patient.date_of_birth
											  )
											: null
									}
									onChange={handleDobChange}
									slotProps={{
										textField: {
											size: "small",
										},
									}}
									minDate={new Date("1900-01-01")}
									maxDate={new Date()}
								/>
							</LocalizationProvider>
						) : (
							<Typography variant="body1">
								{selectedSession.events[eventIndex].patient
									?.date_of_birth || "N/A"}
							</Typography>
						)}
					</Box>

					<Box display="flex" gap={1} alignItems="center">
						<Typography variant="body1" fontWeight="bold">
							Procedure:
						</Typography>
						{edit ? (
							<TextField
								value={event.procedure || ""}
								onChange={(e) =>
									handleUpdateField(
										"procedure",
										e.target.value
									)
								}
								variant="outlined"
								size="small"
								sx={{ maxWidth: 300 }}
							/>
						) : (
							<Typography variant="body1">
								{event.procedure || "N/A"}
							</Typography>
						)}
					</Box>
					<Box display="flex" gap={1} alignItems="center">
						<Typography variant="body1" fontWeight="bold">
							Health Fund:
						</Typography>
						{edit ? (
							<Autocomplete
								size="small"
								disableListWrap
								options={healthFunds}
								getOptionLabel={(option) =>
									option.aliases[1] || ""
								}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								value={
									selectedSession.events[eventIndex].patient
										?.policies?.[0]?.healthFund || null
								}
								onChange={(_, newValue) =>
									handleUpdateField(
										"patient.policies.0.healthFund",
										newValue
									)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										sx={{ minWidth: 400 }}
									/>
								)}
								renderOption={(props, option) => (
									<li {...props} key={option.id}>
										{option.aliases[1]}
									</li>
								)}
								ListboxComponent={ListboxComponent}
							/>
						) : (
							<Typography variant="body1">
								{selectedSession.events[eventIndex].patient
									?.policies?.[0]?.healthFund?.name ??
									selectedSession.events[eventIndex].patient
										?.policies?.[0]?.healthFund
										?.aliases[1] ??
									"N/A"}
							</Typography>
						)}
					</Box>
					<Box display="flex" gap={1} alignItems="center">
						<Typography variant="body1" fontWeight="bold">
							Health Fund #:
						</Typography>
						{edit ? (
							<TextField
								value={
									event.patient?.policies?.[0]
										?.health_fund_no ?? ""
								}
								onChange={(e) =>
									handleUpdateField(
										"patient.policies.0.health_fund_no",
										e.target.value
									)
								}
								size="small"
								variant="outlined"
								sx={{ maxWidth: 200 }}
							/>
						) : (
							<Typography variant="body1">
								{event.patient?.policies?.[0]?.health_fund_no ??
									"N/A"}
							</Typography>
						)}
					</Box>
					<Box display="flex" gap={1} alignItems="center">
						<Typography variant="body1" fontWeight="bold">
							Medicare:
						</Typography>
						{edit ? (
							<TextField
								value={event.patient?.medicare || ""}
								onChange={(e) =>
									handleUpdateField(
										"patient.medicare",
										e.target.value
									)
								}
								variant="outlined"
								size="small"
								sx={{ maxWidth: 200 }}
							/>
						) : (
							<Typography variant="body1">
								{event.patient?.medicare || "N/A"}
							</Typography>
						)}
					</Box>
					<Box display="flex" gap={1} alignItems="center">
						<Typography variant="body1" fontWeight="bold">
							Anaesthetist:
						</Typography>
						{edit ? (
							<Autocomplete
								size="small"
								disableListWrap
								options={anaesthetists}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								value={
									selectedSession.events[eventIndex]
										.anaesthetist || null
								}
								onChange={(_, newValue) =>
									handleUpdateField("anaesthetist", newValue)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										sx={{ minWidth: 300 }}
									/>
								)}
								renderOption={(props, option) => (
									<li {...props} key={option.id}>
										{option.name}
									</li>
								)}
								ListboxComponent={ListboxComponent}
							/>
						) : (
							<Typography variant="body1">
								{event.anaesthetist?.name || "N/A"}
							</Typography>
						)}
					</Box>
					<Divider />
					<Typography variant="body1">
						<strong>Actual Items:</strong>
					</Typography>

					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							alignItems: "baseline",
							gap: 1,
						}}
					>
						{JSON.parse(
							selectedSession.events[eventIndex].actual_items
						)?.map((item, index) => (
							<TextField
								key={index}
								value={item}
								onChange={(e) =>
									handleItemChange(index, e.target.value)
								}
								error={invalidItems.includes(item)}
								inputProps={{
									style: {
										paddingTop: 0,
										paddingBottom: 0,
										width: "60px",
									},
								}}
								sx={{
									"& .MuiOutlinedInput-root": {
										pr: 0, // Remove right padding
									},
									bgcolor: "background.paper",
								}}
								size="small"
								variant="outlined"
								InputProps={{
									endAdornment: (
										<IconButton
											size="small"
											color="error"
											sx={{ padding: 0 }}
											onClick={() =>
												handleDeleteItem(index)
											}
										>
											<DeleteIcon />
										</IconButton>
									),
								}}
							/>
						))}

						<TextField
							value={newItem}
							onChange={(e) => setNewItem(e.target.value)}
							inputProps={{
								style: {
									paddingTop: 0,
									paddingBottom: 0,
									width: "60px",
								},
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									pr: 0, // Remove right padding
								},
								bgcolor: "background.paper",
							}}
							size="small"
							variant="outlined"
							InputProps={{
								endAdornment: (
									<IconButton
										size="small"
										color="primary"
										sx={{ padding: 0 }}
										onClick={handleAddItem}
									>
										<AddCircleIcon />
									</IconButton>
								),
							}}
						/>

						{selectedSession.events[eventIndex].items_validated ===
							true && (
							<Tooltip title="All items valid">
								<CheckCircleIcon
									sx={{ color: "green" }}
									fontSize="small"
								/>
							</Tooltip>
						)}

						<Button
							variant="contained"
							color="primary"
							size="small"
							disableElevation
							disabled={
								isSaving ||
								(selectedSession.events[eventIndex]
									.items_validated !== null &&
									!itemChangesMade[eventIndex])
							}
							onClick={() => {
								setIsSaving(true);
								handleSaveItems(sessionId, eventIndex).finally(
									() => setIsSaving(false)
								);
							}}
						>
							{isSaving ? "Saving..." : "Validate and Save"}
						</Button>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					disableElevation
					variant="contained"
					color="primary"
					disabled={isSavingEvent}
					onClick={() => {
						if (edit && eventFieldChanges[eventIndex]) {
							setIsSavingEvent(true);
							handleSaveEvent().finally(() => {
								setIsSavingEvent(false);
								setEventFieldChanges((prev) => ({
									...prev,
									[eventIndex]: false,
								}));
								setEdit(false); // Optional: exit edit mode after save
							});
						} else if (edit) {
							setEdit(false); // Cancel edit
						} else {
							handleEdit();
						}
					}}
				>
					{isSavingEvent ? (
						<CircularProgress size={18} sx={{ color: "white" }} />
					) : edit ? (
						eventFieldChanges[eventIndex] ? (
							"Save"
						) : (
							"Cancel"
						)
					) : (
						"Edit"
					)}
				</Button>
				<Button
					variant="contained"
					// onClick={handleConfirmDelete}
					color="error"
					size="small"
					disableElevation
				>
					Delete Event
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EventDialog;
