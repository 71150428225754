import React from "react";
import { ListChildComponentProps, FixedSizeList } from "react-window";

const LISTBOX_PADDING = 8; // px
const ITEM_HEIGHT = 56; // Increased to allow for wrapped lines

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;

	return React.cloneElement(data[index], {
		style: {
			...style,
			top: (style.top ?? 0) + LISTBOX_PADDING,
			whiteSpace: "normal", // ✅ allow wrapping
			wordBreak: "break-word",
			lineHeight: 1.4,
			padding: "8px 12px",
			display: "block", // ensure the <li> fills available width
		},
	});
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
	props: React.HTMLAttributes<HTMLElement>,
	ref: React.Ref<HTMLDivElement>
) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const itemCount = itemData.length;

	return (
		<div ref={ref} {...other}>
			<FixedSizeList
				height={
					Math.min(8, itemCount) * ITEM_HEIGHT + 2 * LISTBOX_PADDING
				}
				itemCount={itemCount}
				itemSize={ITEM_HEIGHT}
				width="100%"
				itemData={itemData}
			>
				{renderRow}
			</FixedSizeList>
		</div>
	);
});

export default ListboxComponent;
