import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice.ts";
import authenticateReducer from "./slices/authenticateSlice.ts";
import snackbarReducer from "./slices/snackbarSlice.ts";
import uploadsReducer from "./slices/uploadSlice.ts";
import uploadsResponseModalReducer from "./slices/uploadsResponseModalSlice.ts";
import calendarReducer from "./slices/calendarSlice.ts";
import bugReportModalReducer from "./slices/bugReportModalSlice.ts";
import settingsReducer from "./slices/settingsSlice.ts";
import fieldsReducer from "./slices/fieldsSlice.ts";
import logsReducer from "./slices/logsSlice.ts";
import miniNavReducer from "./slices/miniNavSlice.ts";

export const store = configureStore({
	reducer: {
		user: userReducer,
		authenticate: authenticateReducer,
		snackbar: snackbarReducer,
		uploads: uploadsReducer,
		uploadsResponseModal: uploadsResponseModalReducer,
		calendar: calendarReducer,
		bugReportModal: bugReportModalReducer,
		settings: settingsReducer,
		fields: fieldsReducer,
		logs: logsReducer,
		miniNav: miniNavReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
