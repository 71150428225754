import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Grid,
	TextField,
	Button,
	IconButton,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../store/hooks.ts"; // Custom hooks for typed selector and dispatch
import { updateTiers } from "../../../store/slices/userSlice.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import globalAxios from "../../../globalAxios.js";
import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../../store/slices/snackbarSlice.ts";

const Tiers = () => {
	const user = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	const [saving, setSaving] = useState(false);

	const tiers = useMemo(
		() => user.settings?.tiers || [],
		[user.settings?.tiers]
	);
	const [newName, setNewName] = useState("");
	const [newValue, setNewValue] = useState("");

	const [errorIndexes, setErrorIndexes] = useState<number[]>([]); // Tracks duplicate indices
	const [newTierError, setNewTierError] = useState(false);

	const [initialTiers, setInitialTiers] = useState(() =>
		JSON.stringify(user.settings?.tiers || [])
	);
	const [isModified, setIsModified] = useState(false);

	useEffect(() => {
		setIsModified(JSON.stringify(tiers) !== initialTiers);
	}, [tiers, initialTiers]);

	const isNameUnique = (name, indexToExclude?) => {
		return tiers.every(
			(tier, index) => index === indexToExclude || tier.name !== name
		);
	};

	const handleInputChange = (index, key, value) => {
		if (key === "value" && !/^\d*(\.\d{0,2})?$/.test(value)) {
			return;
		}

		const updatedTiers = tiers.map((tier, i) => {
			if (i === index) {
				return { ...tier, [key]: value }; // Create a new object with the updated key-value
			}
			return tier; // Return other tiers unchanged
		});

		// Check for duplicate names
		if (key === "name") {
			const duplicateIndexes = updatedTiers.reduce(
				(acc, tier, i) =>
					updatedTiers.some((t, j) => j !== i && t.name === tier.name)
						? [...acc, i]
						: acc,
				[]
			);
			setErrorIndexes(duplicateIndexes);
		}

		dispatch(updateTiers(updatedTiers));
	};

	const handleNewTierNameChange = (value) => {
		console.log(value);
		setNewName(value);
		setNewTierError(!isNameUnique(value));
	};

	const handleNewTierValueChange = (value) => {
		console.log(value);
		setNewValue(value);
	};

	const handleAddTier = () => {
		const newTier = { name: newName, value: newValue };

		console.log("adding .. ", newTier);

		const updatedTiers = [...tiers, newTier];
		dispatch(updateTiers(updatedTiers));

		setNewName("");
		setNewValue("");
	};

	const handleDeleteTier = (index) => {
		const updatedTiers = tiers.filter((_, i) => i !== index);
		dispatch(updateTiers(updatedTiers));
	};

	const handleSave = async () => {
		setSaving(true);

		try {
			await globalAxios.post("/user/settings/tiers", {
				tiers,
			});

			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("success"));
			dispatch(setSnackbarText("Successfully saved tiers."));

			setInitialTiers(JSON.stringify(tiers));
			setIsModified(false);

			setSaving(false);
		} catch (error) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Error saving tiers:" + error));

			setSaving(false);
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Box sx={{ flexGrow: 1, padding: 2, maxWidth: "800px" }}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={4}>
						<Box sx={{ fontWeight: "bold", textAlign: "left" }}>
							Tier Name
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box sx={{ fontWeight: "bold", textAlign: "right" }}>
							Tier Fee
						</Box>
					</Grid>
					<Grid item xs={3} sx={{ textAlign: "right" }}>
						<Tooltip title="Create tiers to define additional out of pocket cost. Assign tiers to Surgeon or Hospital to automatically apply additional fees.">
							<IconButton>
								<InfoOutlinedIcon color="primary" />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				{tiers.map((tier, index) => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={index}
						sx={{ marginTop: "0.5rem" }}
					>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								size="small"
								fullWidth
								value={tier.name}
								onChange={(e) =>
									handleInputChange(
										index,
										"name",
										e.target.value
									)
								}
								error={errorIndexes.includes(index)}
								InputProps={{
									endAdornment: errorIndexes.includes(
										index
									) && (
										<Tooltip title="Tier name must be unique">
											<ErrorOutlineIcon color="error" />
										</Tooltip>
									),
								}}
							/>
						</Grid>

						{/* Tier Value */}
						<Grid item xs={3}>
							<TextField
								// label="Tier Value"
								variant="outlined"
								size="small"
								fullWidth
								value={tier.value}
								onChange={(e) =>
									handleInputChange(
										index,
										"value",
										e.target.value
									)
								}
								inputProps={{
									style: { textAlign: "right" },
									inputMode: "decimal",
								}}
							/>
						</Grid>

						<Grid item xs={2}>
							<IconButton
								color="error"
								onClick={() => handleDeleteTier(index)}
							>
								<DeleteIcon />
							</IconButton>
						</Grid>
					</Grid>
				))}
				<Grid
					container
					spacing={2}
					alignItems="center"
					sx={{
						marginTop: 4,
					}}
				>
					<Grid item xs={4}>
						<TextField
							label="New Tier Name"
							variant="filled"
							size="small"
							fullWidth
							value={newName}
							onChange={(e) =>
								handleNewTierNameChange(e.target.value)
							}
							error={newTierError}
							InputProps={{
								endAdornment: newTierError && (
									<Tooltip title="Tier name must be unique">
										<ErrorOutlineIcon color="error" />
									</Tooltip>
								),
							}}
						/>
					</Grid>

					<Grid item xs={3}>
						<TextField
							label="New Tier Value"
							variant="filled"
							size="small"
							fullWidth
							inputProps={{
								style: { textAlign: "right" },
								inputMode: "decimal",
							}}
							value={newValue}
							onChange={(e) =>
								handleNewTierValueChange(e.target.value)
							}
						/>
					</Grid>

					<Grid item xs={2}>
						<Button
							disableElevation
							disableRipple
							variant="contained"
							color="primary"
							onClick={() => handleAddTier()}
							disabled={newTierError}
						>
							Add
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ padding: 2, textAlign: "right" }}>
				<Button
					disableElevation
					disableRipple
					variant="contained"
					color="primary"
					onClick={handleSave}
					disabled={!isModified || saving}
				>
					{saving ? (
						<>
							<CircularProgress size={18} />
							<Box sx={{ ml: 1 }} component="span">
								Saving...
							</Box>
						</>
					) : (
						"Save Changes"
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default Tiers;
