// Controlled component for calendar.
import React, { useEffect, useState } from "react";
import globalAxios from "../../../globalAxios.js";
import { SERVER_URL } from "../../../config.js";

import { useAppDispatch, useAppSelector } from "../../../store/hooks.ts";

import {
	setSnackbarOpen,
	setSnackbarSeverity,
	setSnackbarText,
} from "../../../store/slices/snackbarSlice.ts";

import {
	calculateFeeEstimate,
	calculateSinglePtFeeEstimate,
} from "../helper/feeCalculation.ts";

import ListboxComponent from "./listbox/ListBox.tsx";

import {
	DialogTitle,
	Button,
	DialogContent,
	Box,
	DialogActions,
	CircularProgress,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Dialog,
	TextField,
	IconButton,
	Tooltip,
	Autocomplete,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import { calculateEndTime } from "../helper/eventRendering.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { TimePicker } from "@mui/x-date-pickers";
import { updateSession } from "../../../store/slices/calendarSlice.ts";

import EventDialog from "./eventdialog/EventDialog.tsx";

const CalendarDialog = ({
	open,
	selectedSession,
	setSelectedSession,
	schedules,
	saving,
	deleting,
	edit,
	handleEdit,
	editEvent,
	setEditEvent,
	handleEditEvent,
	hospitals,
	surgeons,
	healthFunds,
	anaesthetists,
	handleDelete,
	handleClose,
	handleUpdateEventItems,
	handleAddEventItem,
	handleDeleteEventItem,
	handleSaveEventItems,
}) => {
	const dispatch = useAppDispatch();

	const user = useAppSelector((state) => state.user);

	// Track input changes.
	const [inputChangesMade, setInputChangesMade] = useState(false);
	const [itemChangesMade, setItemChangesMade] = useState({});
	const [newItemValues, setNewItemValues] = useState({});

	// Track event input changes made in EventDialog - for use when saving session.
	const [eventFieldChanges, setEventFieldChanges] = useState({});

	const [savingSession, setSavingSession] = useState(false);

	const [expandedEventIndex, setExpandedEventIndex] = useState(null);
	const [expandedEvent, setExpandedEvent] = useState(null);

	// Reset tracking when dialog is closed
	useEffect(() => {
		if (!open) {
			setItemChangesMade({});
			setNewItemValues({});
		}
	}, [open]);

	const handleItemChange = (eventIndex, itemIndex, newValue) => {
		const updatedEvents = [...selectedSession.events];

		const currentItems = JSON.parse(
			updatedEvents[eventIndex].actual_items || "[]"
		);

		currentItems[itemIndex] = newValue;

		updatedEvents[eventIndex].actual_items = JSON.stringify(currentItems);

		handleUpdateEventItems(selectedSession.id, updatedEvents);
		setItemChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};

	const handleNewItemInputChange = (eventIndex, value) => {
		setNewItemValues((prev) => ({ ...prev, [eventIndex]: value }));
	};

	const handleItemAdd = (eventIndex) => {
		handleAddEventItem(eventIndex, newItemValues[eventIndex]);
		setNewItemValues((prev) => ({ ...prev, [eventIndex]: "" }));
		setItemChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};

	const handleItemDelete = (eventIndex, itemIndex) => {
		handleDeleteEventItem(eventIndex, itemIndex);
		setItemChangesMade((prev) => ({ ...prev, [eventIndex]: true }));
	};

	// Only disable the button for current saving event.
	const [activeSavingIndex, setActiveSavingIndex] = useState<number | null>(
		null
	);

	const [invalidItems, setInvalidItems] = useState([]);

	// Handle saving the updated items
	const handleSaveItems = async (sessionId, eventIndex) => {
		setActiveSavingIndex(eventIndex);

		const currentItems = JSON.parse(
			selectedSession.events[eventIndex].actual_items || "[]"
		);

		try {
			const result = await handleSaveEventItems(
				sessionId,
				selectedSession.events[eventIndex].id,
				currentItems
			);

			if (result.success) {
				setInvalidItems(JSON.stringify(result.invalid_items));
				setItemChangesMade((prev) => ({
					...prev,
					[eventIndex]: false,
				}));
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Saved changes."));
			}
		} catch (err) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Failed to save."));
		} finally {
			setActiveSavingIndex(null);
		}
	};

	const handleUpdate = (field, value) => {
		if (!value) return; // Ignore null values

		setInputChangesMade(true); // Mark changes as made

		setSelectedSession((prev) => {
			if (field === "hospital" || field === "surgeon") {
				return { ...prev, [field]: value };
			}

			const existingStart = new Date(prev.start);
			const existingEnd = new Date(prev.end);

			const getUpdatedTime = () => {
				let time;
				switch (field) {
					case "end":
						time = existingEnd;
						break;
					case "start":
						time = new Date(prev.start);
						break;
					default:
						time = new Date(prev.start);
				}
				return time;
			};

			// Track the change made by user (time, either start or end)
			const updatedTime = getUpdatedTime();
			updatedTime.setHours(value.getHours(), value.getMinutes(), 0, 0);

			// Force `end` to be at least `00:01`
			if (
				field === "end" &&
				updatedTime.getHours() === 0 &&
				updatedTime.getMinutes() === 0
			) {
				updatedTime.setMinutes(1);
			}

			// Prevent `end` before `start`
			if (field === "end" && updatedTime <= existingStart) {
				const adjustedStart = new Date(updatedTime);
				adjustedStart.setMinutes(adjustedStart.getMinutes() - 1);
				return { ...prev, start: adjustedStart, end: updatedTime };
			}

			// Prevent `start` after `end`
			if (field === "start" && updatedTime >= existingEnd) {
				const adjustedEnd = new Date(updatedTime);
				adjustedEnd.setMinutes(adjustedEnd.getMinutes() + 1);
				return { ...prev, start: updatedTime, end: adjustedEnd };
			}

			return { ...prev, [field]: updatedTime };
		});
	};

	const handleCancelEdit = () => {
		setInputChangesMade(false);
		handleEdit(); // Toggle edit mode off
	};

	const handleSaveSession = async () => {
		// Ensure required fields are filled
		if (
			!selectedSession?.start ||
			!selectedSession?.end ||
			!selectedSession?.hospital ||
			!selectedSession?.surgeon
		) {
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Please fill all required fields."));
			return;
		}

		try {
			setSavingSession(true);

			const updatedSessionData = {
				start_time: new Date(selectedSession.start).toLocaleTimeString(
					"en-AU",
					{
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					}
				),
				end_time: new Date(selectedSession.end).toLocaleTimeString(
					"en-AU",
					{
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					}
				),
				hospital: selectedSession.hospital,
				surgeon: selectedSession.surgeon,
			};

			await globalAxios
				.patch(
					`${SERVER_URL}/user/sessions/${selectedSession.id}`,
					updatedSessionData
				)
				.then((res) => {
					if (res.status === 200 && res.data.session) {
						dispatch(
							updateSession({
								sessionId: selectedSession.id,
								updates: res.data.session,
							})
						);

						setInputChangesMade(false);
						handleEdit();

						dispatch(setSnackbarOpen(true));
						dispatch(setSnackbarSeverity("success"));
						dispatch(
							setSnackbarText("Session updated successfully.")
						);
					} else {
						throw new Error(
							res.data.message || "Failed to update session"
						);
					}
				})
				.catch((error) => {
					console.error("Error updating session:", error);
					dispatch(setSnackbarOpen(true));
					dispatch(setSnackbarSeverity("error"));
					dispatch(setSnackbarText("Error updating session."));
				});
		} catch (error) {
			console.error("Error saving event:", error);
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("Failed to save event."));
		} finally {
			setSavingSession(false); // Stop saving state
		}
	};

	// Confirmation delete modal.
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

	const handleConfirmDelete = () => {
		setConfirmDeleteOpen(true);
	};

	const handleCancelDelete = () => {
		setConfirmDeleteOpen(false);
	};

	const handleConfirmDeleteSession = () => {
		handleDelete();
		setConfirmDeleteOpen(false);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Dialog
				fullScreen
				open={open}
				onClose={() => {
					setInputChangesMade(false);
					handleClose();
				}}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle
					id="form-dialog-title"
					sx={{
						backgroundColor: (theme) => theme.palette.primary.main, // Apply primary color
						color: "white", // Ensure text is readable
						padding: "12px 24px", // Add padding
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					Session Details
					<Button
						onClick={() => {
							setInputChangesMade(false);
							handleClose();
						}}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
						}}
					>
						<Typography>Close</Typography>
					</Button>
				</DialogTitle>
				<DialogContent>
					<Box
						display="flex"
						flexDirection="column"
						gap={1}
						textAlign="left"
						mt={2}
					>
						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="body1" fontWeight="bold">
								Date:
							</Typography>
							<Typography variant="body1">
								{selectedSession?.start
									? new Date(
											selectedSession.start
									  ).toLocaleDateString()
									: ""}
							</Typography>
						</Box>

						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="body1" fontWeight="bold">
								Start:
							</Typography>
							{edit ? (
								<TimePicker
									value={
										selectedSession?.start
											? new Date(selectedSession.start)
											: null
									}
									onChange={(newValue) =>
										handleUpdate("start", newValue)
									}
									ampm={false} // 24-hour format
									slotProps={{ textField: { size: "small" } }} // Style the input
								/>
							) : (
								<Typography variant="body1">
									{selectedSession?.start
										? new Date(selectedSession.start)
												.toLocaleTimeString("en-AU", {
													hour: "2-digit",
													minute: "2-digit",
													hour12: false,
												})
												.replace(":", "")
										: ""}
								</Typography>
							)}
						</Box>

						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="body1" fontWeight="bold">
								End:
							</Typography>
							{edit ? (
								<TimePicker
									value={
										selectedSession?.end
											? toDate(selectedSession.end) // Ensure it's a Date
											: selectedSession?.start
											? toDate(
													calculateEndTime(
														selectedSession.start
															.toISOString()
															.split("T")[0],
														selectedSession.start
															.toTimeString()
															.slice(0, 5),
														selectedSession.nextEvent ??
															null
													)
											  )
											: null
									}
									onChange={(newValue) =>
										handleUpdate("end", newValue)
									}
									ampm={false} // Enforce 24-hour format
									slotProps={{ textField: { size: "small" } }} // Consistent styling
								/>
							) : (
								<Typography variant="body1">
									{selectedSession?.end
										? new Date(selectedSession.end)
												.toLocaleTimeString("en-AU", {
													hour: "2-digit",
													minute: "2-digit",
													hour12: false,
												})
												.replace(":", "")
										: selectedSession?.start
										? new Date(
												calculateEndTime(
													selectedSession.start
														.toISOString()
														.split("T")[0],
													selectedSession.start
														.toTimeString()
														.slice(0, 5),
													selectedSession.nextEvent ??
														null
												) || ""
										  )
												.toLocaleTimeString("en-AU", {
													hour: "2-digit",
													minute: "2-digit",
													hour12: false,
												})
												.replace(":", "")
										: ""}
								</Typography>
							)}
						</Box>

						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="body1" fontWeight="bold">
								Hospital:
							</Typography>

							{edit ? (
								<Autocomplete
									size="small"
									disableListWrap
									options={hospitals}
									getOptionLabel={(option) => option.name}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									value={selectedSession?.hospital || null}
									onChange={(_, newValue) =>
										handleUpdate("hospital", newValue)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{ minWidth: 400 }}
										/>
									)}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									ListboxComponent={ListboxComponent} // Custom virtualised list
								/>
							) : (
								<Typography variant="body1">
									{selectedSession?.hospital?.name}
								</Typography>
							)}
						</Box>

						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="body1" fontWeight="bold">
								Surgeon:
							</Typography>
							{edit ? (
								<Autocomplete
									size="small"
									disableListWrap
									options={surgeons}
									getOptionLabel={(option) =>
										option.first_name +
										" " +
										option.last_name
									}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									value={selectedSession?.surgeon || null}
									onChange={(_, newValue) =>
										handleUpdate("surgeon", newValue)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{ minWidth: 400 }}
										/>
									)}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.first_name +
												" " +
												option.last_name}
										</li>
									)}
									ListboxComponent={ListboxComponent} // Custom virtualised list
								/>
							) : (
								<Typography variant="body1">
									{selectedSession?.surgeon.first_name +
										" " +
										selectedSession?.surgeon.last_name}
								</Typography>
							)}
						</Box>

						<Box display="flex" alignItems="center" gap={1}>
							<Typography variant="body1" fontWeight="bold">
								Assistant Estimate:
							</Typography>
							<Typography variant="body1">
								$
								{calculateFeeEstimate(
									user.settings,
									selectedSession.events,
									schedules
								)?.toFixed(2)}
							</Typography>
						</Box>
					</Box>
				</DialogContent>

				<DialogActions>
					<Button
						size="small"
						disableElevation
						disabled={savingSession}
						onClick={() => {
							if (edit && inputChangesMade) {
								handleSaveSession(); // Save if changes were made
								setInputChangesMade(false); // Reset changes after saving
								handleEdit(); // Exit edit mode
							} else if (edit && !inputChangesMade) {
								handleCancelEdit(); // Cancel edit if no changes
							} else {
								handleEdit(); // Enter edit mode
							}
						}}
						color="primary"
						variant="contained"
					>
						{/* {edit ? (inputChangesMade ? "Save" : "Cancel") : "Edit"} */}
						{savingSession ? (
							<>
								<CircularProgress
									size={18}
									sx={{ color: "white" }}
								/>{" "}
							</>
						) : edit ? (
							inputChangesMade ? (
								"Save"
							) : (
								"Cancel"
							)
						) : (
							"Edit"
						)}
					</Button>
					{deleting ? (
						<Button disabled>
							<svg width={0} height={0}>
								<defs>
									<linearGradient
										id="my_gradient"
										x1="0%"
										y1="0%"
										x2="0%"
										y2="100%"
									>
										<stop offset="0%" stopColor="#e01cd5" />
										<stop
											offset="100%"
											stopColor="#1CB5E0"
										/>
									</linearGradient>
								</defs>
							</svg>
							<CircularProgress
								size={18}
								sx={{
									"svg circle": {
										stroke: "url(#my_gradient)",
									},
								}}
							/>
						</Button>
					) : (
						<Button
							variant="contained"
							onClick={handleConfirmDelete}
							color="error"
							size="small"
							disableElevation
						>
							Delete Session
						</Button>
					)}
				</DialogActions>
				<DialogContent>
					<Box>
						{selectedSession?.events?.length > 0 ? (
							selectedSession.events.map((event, index) => {
								// Calculate individual patient fee estimate
								return (
									<Accordion
										key={index}
										sx={{
											backgroundColor:
												event.items_validated === true
													? "#e3fce4" // Light green for validated
													: event.items_validated ===
													  false
													? "#fce4e4" // Light red for invalid
													: "#f7f7f7", // Light grey for null (not validated yet)
										}}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											sx={{
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Typography
												variant="body1"
												sx={{ flex: 1 }}
											>
												<b>Patient:</b>{" "}
												{event.patient.first_name}{" "}
												{event.patient.last_name} |{" "}
												<b>Procedure:</b>{" "}
												{event.procedure}
											</Typography>
											<Tooltip
												title="Full screen"
												placement="left"
											>
												<IconButton
													size="small"
													disableRipple
													sx={{ marginRight: 3 }}
													onClick={(e) => {
														e.stopPropagation(); // Prevent accordion toggle
														setExpandedEventIndex(
															index
														);
														setExpandedEvent(event);
													}}
												>
													<OpenInFullIcon fontSize="small" />
												</IconButton>
											</Tooltip>
										</AccordionSummary>
										<AccordionDetails>
											<Typography variant="body2">
												DOB:{" "}
												{event.patient.date_of_birth}
											</Typography>
											<Typography variant="body2">
												Medicare:{" "}
												{event.patient.medicare}
											</Typography>
											<Typography variant="body2">
												Health Fund:{" "}
												{event.patient.policies?.[0]
													?.healthFund?.name ??
													event.patient.policies?.[0]
														?.healthFund
														?.aliases?.[1] ??
													"N/A"}
											</Typography>
											<Typography variant="body2">
												Health Fund #:{" "}
												{Array.isArray(
													event.patient.policies
												) &&
												event.patient.policies.length >
													0
													? event.patient.policies[0]
															.health_fund_no
													: "N/A"}
											</Typography>
											<Typography variant="body2">
												Anaesthetist:{" "}
												{event.anaesthetist?.name ||
													"N/A"}
											</Typography>
											<br />
											<Box
												sx={{
													display: "flex",
													flexWrap: "wrap",
													alignItems: "baseline",
													gap: 1,
												}}
											>
												<Typography variant="body2">
													Item No.{" "}
												</Typography>
												{JSON.parse(
													event.actual_items
												)?.map((item, itemIndex) => (
													<TextField
														key={itemIndex}
														value={item}
														onChange={(e) =>
															handleItemChange(
																index,
																itemIndex,
																e.target.value
															)
														}
														error={invalidItems.includes(
															item
														)}
														inputProps={{
															style: {
																paddingTop: 0,
																paddingBottom: 0,
																paddingRight: 0, // Remove right padding
																width: "60px", // Adjust for compact display
															},
														}}
														sx={{
															"& .MuiOutlinedInput-root":
																{
																	pr: 0, // Remove right padding
																},
															bgcolor:
																"background.paper",
														}}
														size="small"
														variant="outlined"
														InputProps={{
															endAdornment: (
																<IconButton
																	size="small"
																	color="error"
																	sx={{
																		padding: 0, // Remove padding from the icon
																	}}
																	onClick={() =>
																		handleItemDelete(
																			index,
																			itemIndex
																		)
																	}
																>
																	<DeleteIcon />
																</IconButton>
															),
														}}
													/>
												))}
												{/* Input field for adding new item */}
												<TextField
													value={
														newItemValues[index] ||
														""
													}
													onChange={(e) =>
														handleNewItemInputChange(
															index,
															e.target.value
														)
													}
													inputProps={{
														style: {
															paddingTop: 0,
															paddingBottom: 0,
															paddingRight: 0, // Remove right padding
															width: "60px",
														},
													}}
													sx={{
														"& .MuiOutlinedInput-root":
															{
																pr: 0, // Remove right padding
															},
														bgcolor:
															"background.paper",
													}}
													size="small"
													variant="outlined"
													InputProps={{
														endAdornment: (
															<IconButton
																size="small"
																color="primary"
																sx={{
																	padding: 0, // Remove padding from the icon
																}}
																onClick={() =>
																	handleItemAdd(
																		index
																	)
																}
															>
																<AddCircleIcon />
															</IconButton>
														),
													}}
												/>
												{event.items_validated && (
													<Tooltip title="All items are valid">
														<CheckCircleIcon
															sx={{
																color: "green",
															}}
															fontSize="small"
														/>
													</Tooltip>
												)}
												<Box
													sx={{ marginLeft: "auto" }}
												>
													<Button
														disableElevation
														variant="contained"
														size="small"
														color="primary"
														onClick={(_e) =>
															handleSaveItems(
																selectedSession.id,
																index
															)
														}
														disabled={
															(saving &&
																activeSavingIndex ===
																	index) ||
															(event.items_validated !==
																null &&
																!itemChangesMade[
																	index
																])
														}
														sx={{
															width: "180px", // Set a fixed width for the button
															display: "flex", // Center-align spinner and text
															justifyContent:
																"center",
															alignItems:
																"center",
														}}
													>
														Validate and Save
													</Button>
												</Box>
											</Box>

											<br />
											{/* Individual Patient Fee Estimate */}
											<Typography variant="body2">
												Assistant Estimate:&nbsp;
												<b>
													$
													{calculateSinglePtFeeEstimate(
														user.settings,
														event,
														schedules
													).toFixed(2)}
												</b>
											</Typography>
										</AccordionDetails>
									</Accordion>
								);
							})
						) : (
							<Typography variant="body2">
								No additional events available.
							</Typography>
						)}
					</Box>
				</DialogContent>
			</Dialog>

			{/* Delete Confirmation Modal */}
			<Dialog
				open={confirmDeleteOpen}
				onClose={handleCancelDelete}
				aria-labelledby="delete-confirmation-title"
			>
				<DialogTitle id="delete-confirmation-title">
					Confirm Deletion
				</DialogTitle>
				<DialogContent>
					<Typography>
						Are you sure you want to delete this session? This
						action cannot be undone.
					</Typography>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
						py: 2,
					}}
				>
					<Button
						disableElevation
						size="small"
						onClick={handleCancelDelete}
						color="primary"
						variant="contained"
					>
						Cancel
					</Button>
					<Button
						disableElevation
						size="small"
						onClick={handleConfirmDeleteSession}
						color="error"
						variant="contained"
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<EventDialog
				open={!!expandedEvent}
				onClose={() => setExpandedEvent(null)}
				selectedSession={selectedSession}
				eventIndex={expandedEventIndex}
				sessionId={selectedSession.id}
				handleUpdateEventItems={handleUpdateEventItems}
				itemChangesMade={itemChangesMade}
				invalidItems={invalidItems}
				setItemChangesMade={setItemChangesMade}
				handleSaveItems={handleSaveItems}
				eventFieldChanges={eventFieldChanges}
				setEventFieldChanges={setEventFieldChanges}
				setSelectedSession={setSelectedSession}
				healthFunds={healthFunds}
				anaesthetists={anaesthetists}
				edit={editEvent}
				setEdit={setEditEvent}
				handleEdit={handleEditEvent}
			/>
		</LocalizationProvider>
	);
};

export default CalendarDialog;

const toDate = (value) => {
	if (!value) return null;
	if (value instanceof Date) return value;
	const parsedDate = new Date(value);
	return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

// const LISTBOX_PADDING = 8; // px
// const ITEM_HEIGHT = 56; // Increased to allow for wrapped lines

// function renderRow(props: ListChildComponentProps) {
// 	const { data, index, style } = props;

// 	return React.cloneElement(data[index], {
// 		style: {
// 			...style,
// 			top: (style.top ?? 0) + LISTBOX_PADDING,
// 			whiteSpace: "normal", // ✅ allow wrapping
// 			wordBreak: "break-word",
// 			lineHeight: 1.4,
// 			padding: "8px 12px",
// 			display: "block", // ensure the <li> fills available width
// 		},
// 	});
// }

// const ListboxComponent = React.forwardRef(function ListboxComponent(
// 	props: React.HTMLAttributes<HTMLElement>,
// 	ref: React.Ref<HTMLDivElement>
// ) {
// 	const { children, ...other } = props;
// 	const itemData = React.Children.toArray(children);
// 	const itemCount = itemData.length;

// 	return (
// 		<div ref={ref} {...other}>
// 			<FixedSizeList
// 				height={
// 					Math.min(8, itemCount) * ITEM_HEIGHT + 2 * LISTBOX_PADDING
// 				}
// 				itemCount={itemCount}
// 				itemSize={ITEM_HEIGHT}
// 				width="100%"
// 				itemData={itemData}
// 			>
// 				{renderRow}
// 			</FixedSizeList>
// 		</div>
// 	);
// });
